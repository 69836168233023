
<template >
    <el-container style="width: auto;background:#f7e7e7">
      <el-header height="100px" style="display: flex;background:#B3C0D1;margin-top: -60px;">
      <div style="width: 40%;height: 100px;line-height: 30px;">
       <h2>深圳市逸兴瑞科技有限公司</h2>   
       <h4 >Shenzhen Yi Xing Rui Technology Co., LTD</h4>
      </div>
  
    <div style="width: 60%;height: 100px;padding: 40px;" > 
    <el-menu  style="background: #B3C0D1;" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"  >
    <el-menu-item index="1" text-color="#fffff"  @click="index_A">首页</el-menu-item>
    <el-menu-item index="2" text-color="#fffff" @click="index_B">产品页面</el-menu-item>
    <el-menu-item index="3" text-color="#fffff" @click="index_C">联系我们</el-menu-item>
  </el-menu>
  </div>
  
  </el-header>
      
      
  <el-main style="margin: 0 auto; width: auto;">
       <div v-if="indexA">
  
      <div style="margin-top: -18px;" >  
      <el-carousel style="width: 1000px;margin: 0 auto;" :interval="5000" height="500px" arrow="always" >
      <el-carousel-item v-for="(item,index) in img" :key="index">
        <el-image :src="item" />
      </el-carousel-item>
       </el-carousel>
      </div>
          <!-- <div v-if="indexB">
           <img src="../assets/lanyashebei.jpg" >   
          </div>
          <div v-if="indexC">
           <img src="../assets/logo.png" >   
          </div> -->   
      <div v-if="indexA">
        <el-divider content-position="center"></el-divider>
        <div style="display: flex;justify-content: center;">
         <h2>成功案例 </h2> 
        </div>  
      
      <div class="number-img">
      <div class="number-img_img">
          <img src="../assets/1.png" >
      </div>
      <div class="number-img_img">
          <img src="../assets/2.png" >
      </div> 
      <div class="number-img_img">
          <img src="../assets/3.png" >
      </div> 
      </div> 
  
      <div class="number-img">
      <div class="number-img_img">
          <img src="../assets/4.png" >
      </div>
      <div class="number-img_img">
          <img src="../assets/5.png" >
      </div> 
      <div class="number-img_img">
          <img src="../assets/6.png" >
      </div> 
      </div> 
      </div>
     </div>
  
       <div v-if="indexB">
          <el-empty description="空页面"></el-empty>
       </div>
      </el-main>
       
      <div v-if="indexA">
      <div  style="width: 100%;background: #fff;display: flex;justify-content: center;">
      <div>
          <img style="" src="../assets/text.png" >
      </div> 
      </div>
       
      <div style="display: flex;width: auto;  justify-content: center;" >
          <div class="grid-content">
          <img class="grid-img" src="../assets/cgq.png" />
          <div style="margin-left: -10px;margin-top: -5px; height: 180px;width: 220px; background: #fff;;">
          <div class="grid-textA">温度传感器</div>
          <div class="grid-textB">温度控制器通过传感器获取当前温度，仪器上按键控制温度</div>  
          </div>
          </div>
      
          <div class="grid-content bg-purple">
          <img src="../assets/circuit.png" />  
          <div style="margin-left: -10px;margin-top: -5px; height: 180px;width: 220px; background: #fff;;">
          <div class="grid-textA">制造执行智能技术系统</div>
          <div class="grid-textB">根据客户需求，提供智能技术解决方案规划和产品生产，努力解决客户需求</div>  
          </div> 
          </div>
      
          <div class="grid-content bg-purple">
          <img src="../assets/printer.png" />  
          <div style="margin-left: -10px;margin-top: -5px; height: 180px;width: 220px; background: #fff;;">
          <div class="grid-textA">蓝牙打印机</div>
          <div class="grid-textB">温度控制器通过传感器获取当前温度，仪器上按键控制温度</div>  
          </div> 
          </div>
      
          <!-- <div class="grid-content bg-purple">
          <img src="../assets/circuit.png" />   
          <div style="margin-left: -10px;margin-top: -5px; height: 180px;width: 220px; background: #fff;;">
          <div class="grid-textA">温度传感器</div>
          <div class="grid-textB">温度控制器通过传感器获取当前温度，仪器上按键控制温度</div>  
          </div>
          </div> -->
       </div>     
      </div>
  
       
       <div v-if="indexC" style="display: flex;">
          <div style="width: 40%;padding-left: 150px;margin-top: -30px;">
           <img src="../assets/map.png" alt="">   
          </div>
          <div style="padding-top: 100px;">
              <h4>地址：深圳市宝安区福海街道新田大道4号宏海江氏大厦1103</h4>
              <h4>联系人：任工</h4>
              <h4>电话：18566229860</h4>
              <h4>邮箱：957211624@qq.com</h4>
          </div>
       </div>
       <el-divider ></el-divider>
        <div style="height: 100px;line-height: 100px;">
            <el-link :underline="false" href="https://beian.miit.gov.cn">粤ICP备2023155239号</el-link>
        </div>
    </el-container>  
      
  </template>
  
  <script>
  export default{
      data(){
          return{
              activeIndex:'1',
              indexA:true,
              indexB:false,
              indexC:false,
              img:[ require("../assets/cgq1.png")  ,require("../assets/printer1.png")],
              // itme:[require("../assets/logo.png") ,require("../assets/img1.png"),require("../assets/lanyashebei.jpg"),require("../assets/img1.png")]
          }
      },
      methods:{
          index_A(){
              this.activeIndex = '1'
              this.indexA = true
              this.indexB = false
              this.indexC = false
          },
  
          index_B(){
              this.activeIndex = '2'
              this.indexA = false
              this.indexB = true
              this.indexC = false
          },
  
          index_C(){
              this.activeIndex = '3'
              this.indexA = false
              this.indexB = false
              this.indexC = true
          }
  
  
      }
  }
  </script>
  
  <style>
  
    .number-img{
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .number-img_img{
      margin-left: 30px;
    }
      .grid-content{
          margin-left: 5%;
          width: 200px;
          height: 400px;
      } 
      .grid-img{
          width: 200px;
          height: 200px;
      } 
  
      .grid-textA{
          height: 50px;
          line-height: 50px;
          display: flex;
          justify-content: center;
      }
      .grid-textB{
          margin: 0 auto;
          width: 160px;
      }
  </style>